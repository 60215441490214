export enum HttpMethods {
  POST = "POST",
  PUT = "PUT",
}

const DefaultHeaders = {
  "Content-Type": "application/json",
};

function transformBody(body: any, headers: Record<string, string>) {
  if (headers["Content-Type"] === "application/json") {
    return JSON.stringify(body);
  }
  return body;
}

export default class HttpClient {
  static get<T>(url: string) {
    return fetch(url).then((response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response.json() as Promise<T>;
    });
  }

  static post(url: string, body: any, headers?: Record<string, string>) {
    const requestHeaders = {
      ...DefaultHeaders,
      ...(headers || {}),
    };

    return fetch(url, {
      method: HttpMethods.POST,
      headers: requestHeaders,
      body: transformBody(body, requestHeaders),
    });
  }

  static put(url: string, body: any, headers?: Record<string, string>) {
    const requestHeaders = {
      ...DefaultHeaders,
      ...(headers || {}),
    };

    return fetch(url, {
      method: HttpMethods.PUT,
      headers: requestHeaders,
      body: transformBody(body, requestHeaders),
    });
  }
}
