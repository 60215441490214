"use client";

import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import cn from "clsx";
import { Fragment } from "react";
import { IoClose } from "react-icons/io5";

type ModalProps = {
  open?: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
  headerText?: string;
};

export function Modal({
  children,
  open,
  onClose,
  headerText,
  showCloseButton = true,
}: React.PropsWithChildren<ModalProps>) {
  return (
    <Transition show={open}>
      <Dialog onClose={onClose} className="relative z-[999999]">
        <TransitionChild
          as={Fragment}
          enter="transition-all ease-in-out duration-300"
          enterFrom="opacity-0 backdrop-blur-none"
          enterTo="opacity-100 backdrop-blur-[.5px]"
          leave="transition-all ease-in-out duration-200"
          leaveFrom="opacity-100 backdrop-blur-[.5px]"
          leaveTo="opacity-0 backdrop-blur-none"
        >
          <div className="fixed inset-0 bg-black/40" aria-hidden="true" />
        </TransitionChild>
        <TransitionChild
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-110 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-110 opacity-0"
          as={Fragment}
        >
          <div className="fixed inset-0 flex w-full items-center justify-center p-4 md:p-5">
            <DialogPanel className="relative h-auto max-h-full max-w-full rounded-lg shadow-xl">
              {showCloseButton && (
                <button
                  onClick={onClose}
                  aria-label="Close panel"
                  className={cn(
                    "hover:bg-grey-100 absolute z-10 inline-flex size-7 items-center justify-center rounded-full bg-white shadow-[0_4px_4px_0px_rgba(0,0,0,0.08)] hover:text-white focus:outline-none md:size-8",
                    "-right-3 -top-3 md:-right-4 md:-top-4",
                  )}
                >
                  <IoClose className="text-2xl text-inherit" />
                </button>
              )}

              {headerText && (
                <div className="bg-grey-40 text-grey absolute h-12 w-full rounded-b-none rounded-t-lg px-6 pt-3 font-semibold">
                  {headerText}
                </div>
              )}

              <div className="h-full max-h-[calc(100vh-85px)] rounded-b-lg">
                <div
                  className={cn(
                    "border-grey-40 text-grey-90 h-full rounded-b-lg border border-solid bg-white shadow-[6px_6px_8px_0_rgba(0,0,0,0.08)]",
                    { "mt-11": !!headerText },
                  )}
                >
                  {children}
                </div>
              </div>
            </DialogPanel>
          </div>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
}
