"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSizeValue = exports.validateCustomSize = void 0;
var validateCustomSize = function (w, h, settings) {
    if (isNaN(w) || isNaN(h))
        return false;
    if (w < 0 || h < 0)
        return false;
    if (w > settings.maxW || w < settings.minW)
        return false;
    if (h > settings.maxH || h < settings.minH)
        return false;
    return true;
};
exports.validateCustomSize = validateCustomSize;
var getSizeValue = function (w, h) { return "".concat(w, "x").concat(h); };
exports.getSizeValue = getSizeValue;
