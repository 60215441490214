import { Customer } from "@commercetools/platform-sdk";
import { useQuery } from "@tanstack/react-query";
import { Session } from "next-auth";

import { createApiClient } from "@/clients/commercetools";

export const fetchMe = async (session: Session | null = null): Promise<Customer | null> => {
  if (!session) return null; // we aren't logged in
  if (session.isAnonymous) return null; // anonymous users don't have a customer profile
  const api = createApiClient(session.accessToken);
  return api
    .me()
    .get()
    .execute()
    .then((res) => res.body);
};

export const useMe = (
  session: Session | null = null,
  initialData?: Customer,
  keepPreviousData = false,
) => {
  return useQuery<Customer | null, Error>({
    queryKey: ["ME", session],
    queryFn: () => fetchMe(session),
    initialData,
    keepPreviousData,
  });
};
