import { CustomerSignin } from "@commercetools/platform-sdk";
import SdkAuth from "@commercetools/sdk-auth";
import { User } from "next-auth";

import http from "@/clients/http";
import { config as env } from "@/config";
import { config } from "./config";

function createAuthClient() {
  return new SdkAuth({
    host: config.authHost,
    projectKey: config.projectKey,
    credentials: {
      clientId: env.CT_CLIENT_ID,
      clientSecret: env.CT_CLIENT_SECRET,
    },
    scopes: [],
    fetch: (url, config) => fetch(url, { ...config, cache: "no-cache" }),
  });
}

export async function getAnonymousToken(): Promise<User | null> {
  const scopes = [`manage_my_orders:${config.projectKey}`];
  const token = await createAuthClient().anonymousFlow(undefined, { scopes });

  // perhaps... create the cart here also?
  // ...

  // extract the customer ID from the scope that was returned
  const regex = /anonymous_id:([a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12})/;
  const match = token.scope.match(regex);
  const uuid = match ? match[1] : "";

  return {
    id: uuid,
    accessToken: token.access_token,
    refreshToken: token.refresh_token,
    expiresIn: token.expires_in,
    isAnonymous: true,
  };
}

export async function getCustomerToken(email: string, password: string): Promise<User | null> {
  const scopes = [
    `manage_my_orders:${config.projectKey}`,
    `manage_my_profile:${config.projectKey}`,
    `manage_my_shopping_lists:${config.projectKey}`,
    `view_published_products:${config.projectKey}`,
    `view_products:${config.projectKey}`,
  ];
  const token = await createAuthClient().customerPasswordFlow(
    { username: email, password },
    { scopes },
  );

  // extract the customer ID from the scope that was returned
  const regex = /customer_id:([a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12})/;
  const match = token.scope.match(regex);
  const uuid = match ? match[1] : "";

  return {
    id: uuid,
    accessToken: token.access_token,
    refreshToken: token.refresh_token,
    expiresIn: token.expires_in,
    isAnonymous: false,
  };
}

export async function getManagerToken(): Promise<string> {
  const scopes = [
    `manage_payments:${config.projectKey}`,
    `manage_orders:${config.projectKey}`,
    `manage_customers:${config.projectKey}`,
    `manage_order_edits:${config.projectKey}`,
    `view_products:${config.projectKey}`,
  ];

  const token = await createAuthClient().clientCredentialsFlow({ scopes });

  return token.access_token;
}

export async function refreshAccessToken(
  refreshToken: string,
): Promise<Pick<User, "accessToken" | "expiresIn"> | null> {
  const token = await createAuthClient().refreshTokenFlow(refreshToken);

  return {
    accessToken: token.access_token,
    expiresIn: token.expires_in,
  };
}

export async function linkAnonymousCartToCustomer(
  anonymousId: string,
  email: string,
  password: string,
) {
  // First, generate a token for our app (not the customer) as the `login`
  // operation requires the `manage_customers` scope which customers don't have
  const token = await createAuthClient().clientCredentialsFlow({
    scopes: [`manage_customers:${config.projectKey}`],
  });

  const signInPayload: CustomerSignin = {
    email,
    password,
    anonymousId,
  };

  // Then, claim the anonymous cart by performing the login request with the customer credentials
  await http.post(`${config.apiHost}/${config.projectKey}/login`, signInPayload, {
    Authorization: `Bearer ${token.access_token}`,
  });
}

export async function getTokenIsActiveStatus(token: string) {
  return (await createAuthClient().introspectToken(token)).active;
}
