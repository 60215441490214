import { AuthOptions } from "next-auth";
import CredentialsProvider from "next-auth/providers/credentials";

import {
  getAnonymousToken,
  getCustomerToken,
  getTokenIsActiveStatus,
  linkAnonymousCartToCustomer,
  refreshAccessToken,
} from "@/clients/commercetools/auth";

/**
 * Module augmentation for `next-auth` types. Allows us to add
 * custom properties to the `session` object and keep type safety.
 * @see https://next-auth.js.org/getting-started/typescript#module-augmentation
 **/
declare module "next-auth" {
  interface Session {
    accessToken: string;
    customerId?: string;
    isAnonymous: boolean;
    anonymousId?: string;
    terminate?: boolean;
  }
  interface User {
    id: string;
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    isAnonymous: boolean;
    terminate?: boolean;
  }
}
declare module "next-auth/jwt" {
  interface JWT {
    accessToken: string;
    refreshToken: string;
    expiresAt: number;
    isAnonymous: boolean;
  }
}

export const authOptions: AuthOptions = {
  providers: [
    CredentialsProvider({
      id: "guest",
      credentials: {},
      async authorize() {
        return getAnonymousToken();
      },
    }),
    CredentialsProvider({
      id: "auth",
      credentials: {
        email: { label: "Email Address", type: "text" },
        password: { label: "Password", type: "password" },
      },
      async authorize(credentials, req) {
        const token = await getCustomerToken(credentials!.email, credentials!.password);

        // if they also had an anonymousId, then link it to their account
        const anonymousId = req?.query?.anonymousId as string;
        if (anonymousId) {
          await linkAnonymousCartToCustomer(
            anonymousId,
            credentials?.email!,
            credentials?.password!,
          );
        }

        return token;
      },
    }),
  ],
  callbacks: {
    jwt: async ({ token, user }) => {
      if (user) {
        // 'user' is only available on sign in, not subsequent calls to jwt()
        token.sub = user.id;
        token.accessToken = user.accessToken;
        token.refreshToken = user.refreshToken;
        token.expiresAt = Date.now() + user.expiresIn * 1000;
        token.isAnonymous = user?.isAnonymous;
      }
      // We can't sign out user in callback related issue [https://github.com/nextauthjs/next-auth/discussions/5334]
      // on each jwt callback use we will check firstly if token is active in CT
      if (token?.accessToken) {
        const isTokenActive = await getTokenIsActiveStatus(token.accessToken);
        if (!isTokenActive) return { ...token, terminate: true };
      }

      // if our access token is expired (or close to it), then refresh it
      if (token.expiresAt < Date.now() /* ...plus some buffer */) {
        const newToken = await refreshAccessToken(token.refreshToken);
        // if we can't get refreshed token we will terminate the session from client
        if (!newToken) {
          return { ...token, terminate: true };
        }
        token.accessToken = newToken.accessToken;
        token.expiresAt = Date.now() + newToken.expiresIn * 1000;
      }

      return token;
    },
    session: ({ session, token }) => {
      session.accessToken = token.accessToken as string;
      session.isAnonymous = token.isAnonymous as boolean;
      session.terminate = token.terminate as boolean;
      if (token.isAnonymous) {
        session.anonymousId = token.sub as string;
      } else {
        session.customerId = token.sub as string;
      }
      return session;
    },
  },
};
