import { useQuery } from "@tanstack/react-query";
import { useSession } from "next-auth/react";

import { getCustomerLoyaltyTier } from "@/clients/talon-one/customer";

export const useCustomerLoyalty = () => {
  const { data: session } = useSession();
  return useQuery(
    ["customer-loyalty-points", session?.customerId],
    () => getCustomerLoyaltyTier(session),
    { retry: false },
  );
};
