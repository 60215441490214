import { ApiRoot, createApiBuilderFromCtpClient } from "@commercetools/platform-sdk";
import { Client, ClientBuilder } from "@commercetools/ts-client";

import { config } from "./config";

export type CtApiClient = ReturnType<typeof ApiRoot.prototype.withProjectKey>;

function createClient(accessToken: string): Client {
  return new ClientBuilder()
    .withProjectKey(config.projectKey)
    .withHttpMiddleware({ host: config.apiHost, httpClient: fetch })
    .withExistingTokenFlow(`Bearer ${accessToken}`, { force: true })
    .build();
}

export function createApiClient(accessToken: string): CtApiClient {
  const client = createClient(accessToken);
  return createApiBuilderFromCtpClient(client).withProjectKey({
    projectKey: config.projectKey,
  });
}
